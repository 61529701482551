.progress {
  padding: 0;
  align-items: unset;
  margin-left: 8px;
}

.insights {
  line-height: 24px;
}

.insightsButtonContainer {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.regenerateInsightsButtonContainer {
  display: flex;
  justify-content: right;
  padding-top: 4px;
}

.fetchInsightsButton {
  width: 200px;
}

.insightsContainer {
  display: flex;
  align-items: center;
}
